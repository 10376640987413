@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../slices/Pricing/styles/PricingCards.css";
@import "../slices/Pricing/styles/PricingCardDynamic.css";
@import "../components/BackgroundTheme/BackgroundTheme.css";

@layer components {
  /**
    * DH Content Full Width
    *
    */
  .dh-content-full { 
    @apply w-full p-4 lg:p-15;
  }

  /**
    * DH Rounded
    *
    */
  .dh-rounded-br { @apply rounded-br-[20%]; }
  .dh-rounded-bl { @apply rounded-bl-[20%]; }
  .dh-rounded-tl { @apply rounded-tl-[20%]; }
  .dh-rounded-tr { @apply rounded-tr-[20%]; }
  .dh-rounded { @apply rounded-[20%]; }

  /**
    * DH Button
    *
    */
  .dh-btn {
    @apply block py-[0.625rem] px-3 rounded-lg text-center text-base leading-6 w-full font-normal text-white-1 bg-primary;
    @apply inline-block md:py-4 md:px-6 md:text-lg md:leading-7 md:w-auto md:font-bold;
    @apply lg:lg:rounded-[0.625rem] lg:py-3 lg:px-8 lg:leading-5;
  }

  .dh-btn-box {
    @apply dh-btn;
    @apply text-sm;
  }
  
  /**
    * DH Pill
    *
    */
  .dh-pill{
    @apply rounded-3xl border-solid border text-white-1 font-medium bg-black-2
    inline-block py-[0.375rem] px-4 text-sm leading-5
    lg:px-4 lg:py-2 lg:text-lg lg:leading-6;
  }
}

@layer components {
  /* @deprecated */
  .dh-content {
    @apply max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8;
  }

  /* @deprecated */
  .dh-title {
    @apply text-3xl font-bold text-gray-900 dark:text-white;
  }

  /* @deprecated */
  .dh-description {
    @apply mt-4 text-gray-500 dark:text-white font-light;
  }

  /* @deprecated */
  .dh-button {
    @apply text-white bg-primary cursor-pointer;
    @apply flex items-center w-full justify-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm lg:text-lg lg:w-auto;
    @apply transform transition-all hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 z-auto;
  }

  /* @deprecated */
  .dh-button-sm {
    @apply dh-button;
    @apply inline-flex px-4 text-sm lg:text-base;
  }

  /* @deprecated */
  .dh-button-lg {
    @apply dh-button;
    @apply px-8 py-3 lg:py-4 lg:px-10;
  }

  /* @deprecated */
  .dh-button-full {
    @apply dh-button;
    @apply m-0 rounded-none lg:rounded-md;
  }

}

