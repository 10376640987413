@tailwind components;

@layer components {
  .pricing-cards {
    @apply flow-root bg-gray-900;
  }

  .pricing-cards__title {
    @apply mx-auto max-w-4xl text-center text-5xl font-bold tracking-tight text-white;
  }

  .pricing-cards__description {
    @apply mx-auto mt-4 max-w-2xl text-center text-lg leading-8 text-white/60;
  }

  .pricing-cards__plan {
    @apply relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:gap-[1px] lg:pb-16;
  }

  .pricing-plan {
    @apply relative rounded-2xl;
  }

  .pricing-plan_featured {
    @apply z-10 bg-white shadow-xl ring-1 ring-gray-900/10 order-first lg:order-none;
  }

  .pricing-plan_standar {
    @apply bg-gray-800/30 ring-1 ring-white/10 lg:ring-0 lg:mt-3 lg:mb-10;
  }

  .pricing-plan_soldout {
    @apply pointer-events-none opacity-75;
  }

  .pricing-plan__wrapper {
    @apply p-8 lg:pt-12 xl:p-10 xl:pt-14;
  }

  .pricing-plan__installments {
    @apply text-white text-sm font-semibold leading-6 uppercase;
  }

  .pricing-plan__installments_featured {
    @apply !text-gray-900;
  }

  .pricing-plan__price {
    @apply flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch;
  }

  .pricing-plan-price {
    @apply mt-2 flex flex-col items-start gap-x-4;
  }

  .pricing-plan-price__final-price {
    @apply text-white text-4xl font-bold tracking-tight;
  }

  .pricing-plan-price__final-price_featured {
    @apply !text-gray-900;
  }

  .pricing-plan-price__button {
    @apply rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-white ;
    @apply focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2;
  }

  .pricing-plan-price__button_soldout {
    @apply !bg-gray-700 !text-gray-300;
  }

  .pricing-plan-discount {
    @apply text-sm leading-5;
  }

  .pricing-plan-discount__percentage {
    @apply bg-green-600 text-white inline-block rounded text-xxs px-0.5 leading-normal;
  }

  .pricing-plan-discount__percentage_soldout {
    @apply !bg-white/30;
  }

  .pricing-plan-discount__original-price {
    @apply text-gray-400 inline-block ml-2;
  }

  .pricing-plan-discount__original-price small {
    @apply line-through text-xs;
  }

  .pricing-plan-discount__original-price_featured {
    @apply !text-gray-500;
  }

  .pricing-plan__benefits {
    @apply mt-8 flow-root sm:mt-10;
  }

  .pricing-plan-bullets {
    @apply -my-2 divide-y border-t text-sm leading-6 lg:border-t-0;
  }

  .pricing-plan-bullets_featured {
    @apply divide-gray-900/5 border-gray-900/5 text-white;
  }

  .pricing-plan-bullets_standar {
    @apply divide-white/5 border-white/5 text-gray-600;
  }

  .pricing-plan-bullets__item {
    @apply flex gap-x-3 py-2 text-white-4
  }

  .pricing-plan-bullets__item_featured {
    @apply !text-gray-600;
  }

  .pricing-plan-bullets__last-places {
    @apply animate-bounce;
  }

  .pricing-plan-bullets__last-places_featured {
    @apply !text-gray-600;
  }

  .pricing-plan-bullets__icon {
    @apply h-6 w-5 flex-none;
  }

  .pricing-plan-bullets__icon_soldout {
    @apply text-white/50;
  }
}
