@tailwind components;

@layer components {
  .pricing-card-dynamic {
    @apply flow-root bg-gray-900;
  }

  .pricing-card-dynamic__title {
    @apply mx-auto max-w-4xl text-center text-4xl font-bold tracking-tight text-white md:text-5xl;
  }

  .pricing-card-dynamic__description {
    @apply mx-auto mt-4 max-w-2xl text-center text-base leading-7 text-white/60 md:text-xl md:leading-8;
  }

  .pricing-card-dynamic-card {
    @apply flex flex-col bg-zinc-900 text-white rounded-2xl w-full md:w-[29.5rem];
  }

  .pricing-card-dynamic-card--selected {
    @apply border-2 z-10 order-first md:order-none rounded-2xl;
  }

  .pricing-card-dynamic-card--not-selected {
    @apply border border-zinc-700 rounded-2xl; 
  }

  .pricing-card-dynamic-card__recommended {
    @apply text-center text-white md:text-2xl rounded-t-[1.25rem] py-2 px-10 font-semibold font-archivo;
  }

  .pricing-card-dynamic-card__content {
    @apply p-4 rounded md:p-6;
  }

  .pricing-card-dynamic-card__content--selected {
    @apply p-6 md:p-10 rounded-2xl bg-zinc-900;
  }

  .pricing-card-dynamic-card__content--not-selected {
    @apply p-6 md:p-10;
  }

  .pricing-card-dynamic-card__button {
    @apply w-full mt-6 py-2 px-3 rounded-md transition-colors duration-200 font-semibold font-archivo;
  }

  .pricing-card-dynamic-card__button--selected {
    @apply bg-white hover:bg-zinc-400 text-black !important;
  }

  .pricing-card-dynamic-card__button--not-selected {
    @apply bg-transparent hover:bg-zinc-700 text-white border-2 border-[#C3C3C3CC] !important;
  }
  
  .pricing-card-dynamic-card__button--primary {
    @apply bg-white hover:bg-zinc-400 text-black !important;
  }

  .pricing-card-dynamic-card__button--secondary {
    @apply bg-transparent hover:bg-zinc-700 text-white border-2 border-[#C3C3C3CC] !important;
  }

  .pricing-card-dynamic-card__button--tertiary {
    @apply bg-stone-500 text-white hover:bg-stone-600 !important;
  }

  .pricing-card-dynamic-card__type {
    @apply text-sm md:text-[1rem] leading-5 text-white font-medium flex items-baseline pb-4;
  }

  .pricing-card-dynamic-card__title {
    @apply text-xl font-semibold md:text-[2rem] overflow-ellipsis pb-4;
  }

  .pricing-card-dynamic-card__discount {
    @apply text-white md:text-xl px-3 py-2 rounded-[1rem] whitespace-nowrap font-semibold md:w-32 text-center justify-center flex;
  }

  .pricing-card-dynamic-card__text-muted {
    @apply md:text-2xl text-[#CBCBCB] font-archivo font-normal;
  }

  .pricing-card-dynamic-card__text-muted-item {
    @apply md:text-base text-[#CBCBCB] font-archivo font-normal;
  }

  .pricing-card-dynamic-card__text-muted-item-white {
    @apply md:text-base text-white font-archivo font-normal flex w-full;
  }

  .pricing-card-dynamic-card__text-bold {
    @apply text-2xl font-semibold md:text-5xl;
  }

  .pricing-card-dynamic-card__text-highlight {
    @apply text-[#67C076] md:text-2xl font-normal font-archivo;
  }

  .pricing-card-dynamic-layout {
    @apply relative justify-center z-10 flex flex-col md:flex-row gap-6 max-w-full mx-auto p-4 mt-12 items-center;
  }

  .pricing-card-dynamic-card__icon {
    object-fit: contain;
  }

  .pricing-card-dynamic-card__divider {
    @apply flex flex-col w-full h-[0.0625rem] bg-[#353535];
  }
}
