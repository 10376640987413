@tailwind components;

@layer components {
 
  .background-main {
    @apply relative flex flex-col;
  }

  .background {
    @apply absolute right-0 bottom-0 overflow-hidden w-full; 
  }

  .background__pattern {
    @apply w-full h-full object-cover;
  }
}
