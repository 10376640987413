
@import "components.css";

/* fix height of carousel item */
.rec.rec-item-wrapper {
  height: 100%;
}

/* fix margin of carousel */
.rec.rec-slider-container {
  margin: 0;
}

/* fix width of carousel item on desktop */
@media (min-width: 850px) {
  .rec.rec-item-wrapper {
    max-width: 100%;
  }
}

/* fix <a> style in rich text */
.my-richtext-a-underline a {
  text-decoration: underline;
}

/* fix <p> margin in rich text */
.my-richtext-2 p {
  display: inline-block;
  width: 100%;
  margin-top: 0.5rem /* 8px */;
  margin-bottom: 0.5rem /* 8px */;
}
.my-richtext-6 p {
  display: inline-block;
  width: 100%;
  margin-top: 1.5rem /* 24px */;
  margin-bottom: 1.5rem /* 24px */;
}

/* fix list style in rich text */
.my-richtext-list-disc ul {
  padding-left: 1.063rem;
}

.my-richtext-list-disc ul > li {
  list-style-type: disc;
  margin-bottom: 1.25rem /* 20px */;
}

.my-richtext-list-checklist ul {
  padding-left: 1.063rem;
  padding-top: 24px;
}

.my-richtext-list-checklist ul > li {
  position: relative;
  list-style-type: none;
  margin-bottom: 16px;
}

.my-richtext-list-checklist ul > li::after {
  content: '✓';
  position: absolute;
  left: -1.25rem;
  top: 0;
  color: #00CC7E;
  font-weight: 800;
}

.my-richtext-list-numeric ol {
  padding-left: 1.25rem;
  margin: 1.25rem 0 /* 20px */;
}

.my-richtext-list-numeric ol > li {
  list-style-type: decimal;
  margin: 0.5rem 0 /* 8px */;
}
